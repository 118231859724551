<template>
  <div>
    <v-card outlined class="grey lighten-3 rounded-md">
      <v-card-text class="py-1">
        <v-row align="center">
          <v-col>
            <div>
              <p tabindex="0" class="text-caption my-0 font-weight-light">
                Your bank
              </p>
              <p tabindex="0" class="text-body-1 my-0">
                {{ $store.state.selectedBank.BankName }}
              </p>
            </div>
          </v-col>
          <v-col cols="3">
            <BankLogo
              :bank="$store.state.selectedBank"
              :bankLogoHeight="bankLogoHeight"
            />
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import BankLogo from '@/components/BankLogo.vue';
export default {
  name: 'selectedBankAndLogo',
  components: {
    BankLogo
  },
  data() {
    return {
      bankLogoHeight: 50
    }
  },
  methods: {
    fdfdf() {
      try {
        const logoSrc = require(`@/assets/BankLogos/${this.$store.state.selectedBank.BankName}.svg`)
        return logoSrc
      } catch (e) {
        return false
      }
    },
  }
}
</script>

<style>
</style>